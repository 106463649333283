dmx.Component('session-manager', {

    initialData: {
        data: {}
    },

    methods: {
        set: function(key, value) {
            const val = JSON.stringify(value);
            if (val != null) {
                window.sessionStorage.setItem('dmxState-' + key, val);
            } else {
                window.sessionStorage.removeItem('dmxState-' + key);
            }
            this.getData();
        },

        remove: function(key) {
            window.sessionStorage.removeItem('dmxState-' + key);
            this.getData();
        },

        removeAll: function() {
            Object.keys(window.sessionStorage).forEach(function(key) {
                if (key.startsWith('dmxState-')) {
                    window.sessionStorage.removeItem(key);
                }
            });
            this.getData();
        }
    },

    render: function(node) {
        this.getData();
    },

    getData: function() {
        this.set('data', Object.keys(window.sessionStorage).reduce(function(data, key) {
            if (key.startsWith('dmxState-')) {
                try {
                    data[key.substr(9)] = JSON.parse(window.sessionStorage.getItem(key));
                } catch (e) {
                    console.warn('Error parsing JSON: ' + window.sessionStorage.getItem(key));
                }
            }

            return data;
        }, {}));
    }

});
