dmx.Component('query-manager', {

    initialData: function () {
        this.search = window.location.search;

        return {
            data: this.parseQuery()
        };
    },

    methods: {
        set: function(key, value) {
            this.setQueryParam(key, value);
        },

        remove: function(key) {
            this.setQueryParam(key);
        },

        removeAll: function() {
            this.setQueryParam();
        }
    },

    render: function(node) {
        this.search = window.location.search;
        this.updateData = this.updateData.bind(this);
        // only need to update on these events
        window.addEventListener('popstate', this.updateData);
        window.addEventListener('pushstate', this.updateData);
        window.addEventListener('replacestate', this.updateData);
    },

    updateData: function() {
        if (this.search !== window.location.search) {
            this.search = window.location.search;
            this.set('data', this.parseQuery());
        }
    },

    setQueryParam: function(key, value) {
        let updated = false;
        let params = dmx.clone(this.data.data);

        if (value == null) {
            if (key == null) {
                params = {};
                updated = true;
            } else if (params[key]) {
                delete params[key];
                updated = true;
            }
        } else if (params[key] != value) {
            params[key] = value;
            updated = true;
        }

        if (updated) {
            if (window.URLSearchParams) { // use URLSearchParams if available
                var url = new URL(window.location);
                url.search = new URLSearchParams(params);
                window.history.pushState(null, null, url);
            } else {    
                window.history.pushState(null, null, window.location.pathname + this.buildQuery(params) + window.location.hash);
            }
        }
    },

    buildQuery: function(data) {
        const keys = Object.keys(data);

        return keys.length ? '?' + keys.reduce(function(query, key) {
            if (query) query += '&';
            query += encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
            return query;
        }, '') : '';
    },

    parseQuery: function() {
        const query = this.search.replace(/^\?/, '');

        return query.split('&').reduce(function(data, part) {
            const p = part.replace(/\+/g, ' ').split('=');
            if (p[0]) data[decodeURIComponent(p[0])] = decodeURIComponent(p[1] || '');
            return data;
        }, {});
    }

});
